import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { formatImageUrl } from "../../../helper/helper";

const ScotchWhisky = (props: any) => {
  const { data } = props;
  let logoImage: any = formatImageUrl(data?.logo?.data?.attributes?.url);

  return (
    <>
      <div className="aboutus-firstfold-container">
        <Container>
          <Row>
            <Col md={12} xs={12}>
              <div className="inner-container">
                <h2 className="headingText text-center">{data?.title}</h2>
                <ReactSVG src={logoImage} className="text-center logo" />
                <p className="desc1 text-center">{data?.sub_title}</p>
                <p className="desc2 text-center">{data?.description}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ScotchWhisky;
