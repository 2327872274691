import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../common/CommonButton/Button";
// import Link from "next/link";

const OurStory = (props: any) => {
  const { data } = props;
  const screenWidth = window.innerWidth;

  function scrollWithOffset(id: any, offset: any) {
    const element = document.getElementById(id);
    if (element) {
      // Check if the element exists
      const yOffset = -offset;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    } else {
      console.warn(`Element with ID "${id}" not found.`);
    }
  }

  const typeofFunc = (type: any, data: any) => {
    switch (type) {
      case "paragraph":
        return renderParagrph(data);
      case "text":
        return renderText(data);
      case "link":
        return renderLink(data);

      default:
        return null;
    }
  };

  function renderLink(data: any) {
    return (
      <a href={data?.url} target="_blank">
        {" "}
        {data?.children[0].text}
      </a>
    );
  }

  function renderParagrph(data: any) {
    return (
      <p className="subtext">
        {data?.map((el: any) => {
          return typeofFunc(el?.type, el);
        })}
      </p>
    );
  }
  function renderText(data: any) {
    return <>{data?.text}</>;
  }

  return (
    <div className="aboutus-ourstory-container">
      <h2 className="text-center text-uppercase headingText">{data?.header}</h2>
      <div className="year-containerMobile onlyMobile">
        <ul>
          {data?.items.map((item: any, index: any) => {
            return (
              <a
                onClick={() =>
                  scrollWithOffset(`${item?.year ? item?.year : "/"}`, 100)
                }
              >
                <li key={index}>{item.year}</li>
              </a>
            );
          })}
        </ul>
      </div>
      <div className="card-container">
        <Container>
          {screenWidth > 900
            ? data?.items.map((item: any, index: any) => {
                return (
                  <>
                    {index % 2 == 0 && (
                      <Row key={index} className="pt-5" id={item?.year}>
                        <Col md={6} xs={12}>
                          <div className="left-innercontainer">
                            <img
                              src={
                                item?.image?.data?.attributes?.url
                                  ? item?.image?.data?.attributes?.url
                                  : ""
                              }
                              className="image"
                            />
                          </div>
                        </Col>
                        <Col md={6} xs={12}>
                          <div className="right-innercontainer" id={item?.year}>
                            <div className="year">{item?.year}</div>
                            <p className="headingsub-text">{item?.title}</p>
                            <p className="subtext">
                              {item?.description?.map(
                                (des: any, index: any) => {
                                  return typeofFunc(des?.type, des?.children);
                                }
                              )}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}

                    {index % 2 != 0 && (
                      <Row className="pt-5" key={index}>
                        <Col md={6} xs={12}>
                          <div
                            className="bottomleft-innercontainer"
                            id={item?.year}
                          >
                            <div className="year">{item?.year}</div>
                            <p className="headingsub-text">{item?.title}</p>
                            <p className="subtext">
                              {item?.description?.map(
                                (des: any, index: any) => {
                                  return typeofFunc(des?.type, des?.children);
                                }
                              )}
                            </p>
                          </div>
                        </Col>
                        <Col md={6} xs={12}>
                          <div className="bottomright-innercontainer">
                            <img
                              src={
                                item?.image?.data?.attributes?.url
                                  ? item?.image?.data?.attributes?.url
                                  : ""
                              }
                              className="image"
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </>
                );
              })
            : data?.items.map((item: any, index: any) => {
                return (
                  <Row key={index} id={item?.year} className="pt-5">
                    <Col md={6} xs={12}>
                      <div className="left-innercontainer">
                        <img
                          src={
                            item?.image?.data?.attributes?.url
                              ? item?.image?.data?.attributes?.url
                              : ""
                          }
                          className="image"
                        />
                      </div>
                    </Col>
                    <Col md={6} xs={12}>
                      <div className="right-innercontainer" id={item?.year}>
                        <div className="year">{item?.year}</div>
                        <p className="headingsub-text">{item?.title}</p>
                        <p className="subtext">
                          {item?.description?.map((des: any, index: any) => {
                            return typeofFunc(des?.type, des?.children);
                          })}
                        </p>
                      </div>
                    </Col>
                  </Row>
                );
              })}
        </Container>
        <div className="year-container onlyDesktop">
          <ul>
            {data?.items.map((item: any, index: any) => {
              return (
                <a
                  onClick={() =>
                    scrollWithOffset(`${item?.year ? item?.year : "/"}`, 100)
                  }
                >
                  <li key={index}>{item?.year}</li>
                </a>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="cheers-wrapper">
        <h2 className="headingText text-center">{data?.end_note}</h2>
        {data?.cta?.link ? (
          <Button
            btnText={data?.cta?.title}
            btnClass={"shopnowbtn"}
            btnLink={data?.cta?.link}
            linkOpenState={data?.cta?.open_in_new_tab == true ? "_blank" : ""}
          />
        ) : null}
      </div>
    </div>
  );
};

export default OurStory;
