import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../common/CommonButton/Button";
import { formatImageUrl } from "../../../helper/helper";


const OurTeam = (props: any) => {
  const { data } = props;
  return (
    <div className="aboutus-ourteam-container">
      <h2 className="main-heading text-center text-uppercase">
        {data?.header}
      </h2>
      <Container>
        <Row>
          {data.members &&
            data?.members.map((item: any, index: any) => {
              let bgImage = formatImageUrl(item?.photo?.data?.attributes?.url);
              return (
                <Col md={4} xs={8} key={index}>
                  <div className="card-container">
                    <div className="image-wrapper">
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${bgImage})`,
                        }}
                      ></div>
                    </div>
                    <h2 className="card-name">{item?.name}</h2>
                    <p className="designation-text">{item?.position}</p>
                    <p className="description">{item?.description}</p>
                  </div>
                </Col>
              );
            })}
        </Row>
        {/* <Col md={12}> */}
          <div className="button-wrapper">
          {
            data?.cta?.link ?
              <Button btnText={data?.cta?.title} btnClass={"shopnowbtn"} btnLink={data?.cta?.link} linkOpenState={data?.cta?.open_in_new_tab == true ? "_blank" : ""} />
              : null
          }
          </div>
        {/* </Col> */}
      </Container>
    </div>
  );
};

export default OurTeam;
