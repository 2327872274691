"use client";
import ExploretheDuncan from "@/components/aboutus/ExploretheDuncan";
import OurStory from "@/components/aboutus/OurStory";
import OurTeam from "@/components/aboutus/OurTeam";
import ScotchWhisky from "@/components/aboutus/ScotchWhisky";
import BuyNowCart from "@/components/common/BuyNowCart";
import SignupForFree from "@/components/landingpage/SignupForFree";
import NewMainLayout from "@/components/layout/newMainLayout";
import ScoMeta from "@/components/scometa";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";
import React, { useEffect, useState } from "react";

const Aboutus = (props: any) => {
  const { data, meta, countryCode } = props;
  console.log("meta", meta);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = React.useState(false);
  const [aboutusData, setAboutusData]: any = useState(null);

  useEffect(() => {
    if (data) {
      setAboutusData(data?.data?.attributes?.blocks);
    }
  }, [data]);

  const renderAboutusPage = () => {
    return (
      <>
        {aboutusData && aboutusData.length > 0
          ? aboutusData.map((content: any, index: any) => {
              switch (content.__component) {
                case "about.info-banner":
                  return (
                    <div key={index}>
                      <ScotchWhisky key={content?.id + index} data={content} />
                    </div>
                  );
                case "about.our-story-timeline":
                  return <OurStory key={content?.id + index} data={content} />;

                case "about.our-team":
                  return <OurTeam key={content?.id + index} data={content} />;

                case "about.explore-our-whisky":
                  return (
                    <ExploretheDuncan
                      data={content}
                      key={content?.id + index}
                    />
                  );

                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  return (
    <>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      {aboutusData && (
        <NewMainLayout
          checkout={checkout}
          toggleCartSidebar={toggleCartSidebar}
        >
          {aboutusData && renderAboutusPage()}
          <SignupForFree />
        </NewMainLayout>
      )}
    </>
  );
};

export default Aboutus;
