import React from "react";
import Brandtab from "./Brandtab";
import Button from "../common/CommonButton/Button";
import { formatImageUrl } from "../../../helper/helper";
import Link from "next/link";

const ExploretheDuncan = (props: any) => {
  const { data } = props;

  let bgImage = formatImageUrl(data?.right_logo?.data?.attributes?.url);
  return (
    <div className="aboutus-exploretheduncan-container">
      <div
        className="inner-container"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="headingText text-center">{data?.title}</h2>
        {/* <p className="description">{data?.description}</p> */}
        {/* <Link
          href={data?.cta?.link ? data?.cta?.link :'/'}
          target={data?.cta?.open_in_new_tab == true ? "_blank" : "_self"}
        >
          <Button btnText={data?.cta?.title} btnClass={"explorebtn"} />
        </Link> */}
      </div>
      <Brandtab tabsData={data?.brands} />
    </div>
  );
};

export default ExploretheDuncan;
