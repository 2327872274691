"use client";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { ReactSVG } from "react-svg";
import { formatImageUrl } from "../../../helper/helper";
import Link from "next/link";

const Brandtab = (props: any) => {
  const { tabsData } = props;

  return (
    <div className="tabinner-container">
      <div className="tabcontainer ">
        <Container>
          <Row>
            <ul>
              {tabsData.map((item: any, index: number) => {
                let tab_image: any = formatImageUrl(
                  item?.image?.data?.attributes?.url
                );

                return (
                  <li key={index}>
                    <Link
                      href={item?.link ? item?.link : "/"}
                      target={
                        item?.open_in_new_tab == true ? "_blank" : "_self"
                      }
                    >
                      <img src={tab_image} alt="img" />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Brandtab;
